import * as React from "react";
import * as TextStyles from "./text.module.css";

const Text = (props) => {
  const { type, className, children } = props;
  const classes = [TextStyles[type], TextStyles.letterSpacing, className].join(" ");

  const tags = {
    displayBold: "span",
    displayRegular: "span",
    heading1: "h1",
    heading2: "h2",
    heading3: "h3",
    heading4: "h4",
    heading5: "h5",
    heading6: "h6",
    lead: "span",
    bodyRegular: "p",
    blockquoteRegular: "p",
    blockquoteLarge: "p",
    labelLarge: "label",
    labelMedium: "label",
    labelSmall: "label",
    button16Pt: 'span',
    comingSoon: 'p',
    comingSoonBold: 'p'
  };

  const TagName = type && type in tags ? tags[type] : "span";

  return <TagName {...props} className={classes}>{children}</TagName>;
};

export default Text;
