import * as React from "react"
import Navbar from "../organisms/navbar"
import * as styles from "./layout.module.css"

const Layout = ({ children }) => {
  return (
    <div className={styles.container}>
      <Navbar />
      {children}
    </div>
  )
}

export default Layout