// extracted by mini-css-extract-plugin
export var blockquoteLarge = "text-module--blockquote-large--HDM7H";
export var blockquoteRegular = "text-module--blockquote-regular---btYx";
export var bodyRegular = "text-module--body-regular--CG9TB";
export var button16Pt = "text-module--button16-pt--QqFJJ";
export var comingSoon = "text-module--coming-soon--6fsnE";
export var comingSoonBold = "text-module--coming-soon-bold--IW3fR";
export var displayBold = "text-module--display-bold--8Pb0b";
export var displayRegular = "text-module--display-regular--C2LCt";
export var heading1 = "text-module--heading1--bdqPL";
export var heading2 = "text-module--heading2--EXmU7";
export var heading3 = "text-module--heading3--G5nwc";
export var heading4 = "text-module--heading4--XfzoC";
export var heading5 = "text-module--heading5--r3yNl";
export var heading6 = "text-module--heading6--rVMVS";
export var labelLarge = "text-module--label-large--2ojTL";
export var labelMedium = "text-module--label-medium--yd70B";
export var labelSmall = "text-module--label-small--DI2d7";
export var lead = "text-module--lead--X293u";
export var letterSpacing = "text-module--letterSpacing--Xhitf";