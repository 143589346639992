import { styled } from "@mui/material/styles"
import { Link } from "gatsby"
import IconButton from "@mui/material/IconButton"

export const LinkStyled = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  flex-grow: 1;
`

export const IconBtnStyled = styled(IconButton)({
  margin: "0 5px",
  width: "50px",
  height: "50px",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    width: "50px",
    height: "50px",
  },
})
