import * as React from "react"
import Drawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import {
  IconBtnStyled,
  BoxPF,
  ListItemTextPF1
} from "./styles"
import MenuIcon from "../../../images/menu.svg"
import { menuData } from "./data"
import Networks from "../networks"
import * as DrawerStyles from "./drawer.module.css"
import Text from "../../text/text"
import exitIcon from "../../../images/exit.svg"
import { Link } from "gatsby"
import styled from "styled-components"

const GatsbyLink = styled(Link)`
  text-decoration: none;
`

export default function TemporaryDrawer(props) {

  const { stateChanger } = props
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  })

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {

      return
    }
    stateChanger({ isNetworkHidden: open })
    setState({ ...state, [anchor]: open })
  }

  const list = anchor => (
    <BoxPF
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : "100%" }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className={DrawerStyles.content}>
        <Networks className={DrawerStyles.networks} />
        <img
          className={DrawerStyles.iconExit}
          onClick={toggleDrawer(anchor, false)}
          src={exitIcon}
          alt="icon"
        />
      </div>
      <List>
        {menuData.map(item => (
          <GatsbyLink key={item.id} to={item.url}>
            <div className={DrawerStyles.menuItem}>
              <ListItem>
                <ListItemIcon>
                  <img src={item.icon} alt="icon" />
                </ListItemIcon>
                <ListItemTextPF1 primary={item.name} />
              </ListItem>
            </div>
          </GatsbyLink>
        ))}
      </List>
      <Text type="comingSoon" className={DrawerStyles.rightsReserved}>
        All rights reserved  © {new Date().getFullYear()}{" "} Metadao OÜ
      </Text>
    </BoxPF>
  )

  return (
    <>
      <IconBtnStyled
        size="small"
        onClick={toggleDrawer("right", true)}
      >
        {!state["right"] && <img src={MenuIcon} alt="Menu" />}
      </IconBtnStyled>
      <Drawer
        anchor="right"
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        PaperProps={{
          sx: {
            backgroundColor: "var(--primary-dark)",
            width: "320px",
            opacity: "0.8"
          }
        }}
      >
        {list("right")}
      </Drawer>
    </>
  )
}
