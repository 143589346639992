import { styled } from "@mui/material/styles"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import ListItemText from "@mui/material/ListItemText"

export const ListItemTextPF1 = styled(ListItemText)({
  span: {
    fontWeight: "var(--font-weight-500)",
    fontSize: "1.13rem",
    lineHeight: "27px",
    opacity: "0.8",
  },
})

export const IconBtnStyled = styled(IconButton)({
  margin: "0 5px",
  width: "50px",
  height: "50px",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    width: "50px",
    height: "50px",
  },
})

export const DividerPF = styled(Divider)({
  marginTop: "12px",
  color: "red",
})

export const BoxPF = styled(Box)({
  ul: {
    padding: "35px",
  },
  height: "100%",
  display: "flex",
  flexDirection: "column",
})
