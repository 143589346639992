import * as React from "react"
import { IconBtnStyled } from "../organisms/styles"
import Discord from "../../images/discord.svg"
import Instagram from "../../images/instagram.svg"
import Twitter from "../../images/twitter.svg"
import Facebook from "../../images/facebook.svg"

const Networks = (props) => {
  const openLink = url => window.open(url, "_blank")

  return (
    <>
      <div {...props}>
        <IconBtnStyled
          size="small"
          onClick={() => openLink("https://discord.gg/7VrnFnVK7z")}
        >
          <img src={Discord} alt="Discord" />
        </IconBtnStyled>
        <IconBtnStyled
          size="medium"
          onClick={() => openLink("https://www.instagram.com/vineber_app/")}
        >
          <img src={Instagram} alt="Instagram" />
        </IconBtnStyled>
        <IconBtnStyled
          size="medium"
          onClick={() => openLink("https://twitter.com/vineber_app")}
        >
          <img src={Twitter} alt="Twitter" />
        </IconBtnStyled>
        <IconBtnStyled
          size="medium"
          onClick={() => openLink("https://www.facebook.com/vineber_app")}
        >
          <img src={Facebook} alt="Facebook" />
        </IconBtnStyled>
      </div>
    </>
  )
}

export default Networks
