import * as React from "react"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Logo from "../../images/logo-Vineber.png"
import * as Styles from "./navbar.module.css"
import { LinkStyled } from "./styles"
import Networks from "../molecules/networks"
import DrawerPF from '../molecules/drawer/drawer';

const Navbar = () => {

  const [state, setState] = React.useState({
    isNetworkHidden: false,
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="absolute"
        color="transparent"
        sx={{ boxShadow: "unset" }}
      >
        <Toolbar sx={{ margin: "20px", padding: 0 }}>
          <LinkStyled to="/">
            <img src={Logo} alt="Club Vineber" />

          </LinkStyled>
          <Box
            className={Styles.networks}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            {!state["isNetworkHidden"] && <Networks />}
          </Box>
          
          <DrawerPF stateChanger={setState} className={Styles.menu}/>
          
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default Navbar
