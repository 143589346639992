import Icon from "../../../images/icon-without.png";

export const menuData = [
    {
        id: 'menuData0',
        name: 'Coming Soon',
        description: 'Short Description',
        icon: Icon,
        url: '/coming-soon'
    }
]